<template>
    <div style="background-color: white;">
        <header>
            <v-container>
                <v-row align="center" class="about-row">
                    <v-col cols="12" sm="6" class="py-5">
                        <p class="display-1 font-weight-bold secondary--text mt-5 d-xl-none">ABOUT FARE33</p>
                        <p class="regular primary--text text-justify d-xl-none">Fare 33 was established by experts with over 30 years of experience in multiple facets of the travel industry. Our team has been involved in the evolution of the technological advancement of ticketing from the very beginning. With that knowledge and years of experience with the airlines, we are uniquely situated to provide you with the best prices and the smoothest user experience in the market.</p>
                        <p class="display-3 font-weight-bold secondary--text mt-5 d-none d-xl-flex">ABOUT FARE33</p>
                        <p class="display-1 primary--text text-justify d-none d-xl-flex">Fare 33 was established by experts with over 30 years of experience in multiple facets of the travel industry. Our team has been involved in the evolution of the technological advancement of ticketing from the very beginning. With that knowledge and years of experience with the airlines, we are uniquely situated to provide you with the best prices and the smoothest user experience in the market.</p>
                    </v-col>
                    <v-col cols="12" sm="6" class="d-none d-sm-flex pb-5">
                        <img class="mx-auto" :id="$vuetify.breakpoint.name='xl' ? '' : 'logo'" src="http://dev-booking-engine.fare33.com/images/assets/logoCover.png" :height="$vuetify.breakpoint.name='xl' ? '500' : ''">
                    </v-col>
                </v-row>
            </v-container>
            <div class="arrow slide-bottom">
                <v-btn icon x-large @click="$vuetify.goTo('.service-section')"><v-icon x-large color="secondary">mdi-arrow-down</v-icon></v-btn>
            </div>
            <div class="wave"><img src="../static/icons/wave.svg"></div>
        </header>
        <v-container class="service-section">
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/conveyor.png">
                        <v-card-title><span class="mx-auto secondary--text">Experience</span></v-card-title>
                        <v-card-text class="primary--text">
                            30 years of experience in the Travel Industry
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/info.png">
                        <v-card-title><span class="mx-auto secondary--text">Customer Care</span></v-card-title>
                        <v-card-text class="primary--text">
                            Large experienced staff experienced in customer care for the travel sector
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/money.png">
                        <v-card-title><span class="mx-auto secondary--text">Prices</span></v-card-title>
                        <v-card-text class="primary--text">
                            Most competitive prices in the industry
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/pro.png">
                        <v-card-title><span class="mx-auto secondary--text">Promotions</span></v-card-title>
                        <v-card-text class="primary--text">
                            Promotional fares offered daily
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/clock.png">
                        <v-card-title><span class="mx-auto secondary--text">Quick&Easy</span></v-card-title>
                        <v-card-text class="primary--text">
                            Get cheap flight tickets and hotels rooms in a simple few short clicks!
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card min-height="200" class="text-center py-5">
                        <img src="../static/icons/discount.png">
                        <v-card-title><span class="mx-auto secondary--text">Offers</span></v-card-title>
                        <v-card-text class="primary--text">
                            Special offers to our loyal clients
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
  title: '-About Us',
  created () {
    window.scrollTo(0, 0)
  }
}
</script>

<style>
    #about {
        line-height: 35px !important;
    }
    header {
        width: 100%;
        height: 100vh;
        position: relative;
        background-color: #f8f9fb;
    }
    .wave {
        position: absolute;
        bottom: -10px;
        width: 100%;
    }
    .wave img {
        width: 100%;
    }
    .about-row {
        height: 74vh;
    }
    #logo {
        height: 65vh;
        margin: 0 auto;
    }
    .arrow {
        position: absolute;
        top: 78%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
    }
    .slide-bottom {
        -webkit-animation: slide-bottom 1s infinite alternate-reverse both;
                animation: slide-bottom 1s infinite alternate-reverse both;
    }
    @-webkit-keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
    }
    @keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }
    }
</style>
